:root {
    --background-color: #fff;
    --text-color: #000;
    --main-color: #0063f9;
    --nav-link-color: #000;
    --highlight-color: rgba(53, 255, 240, 0.45);
    --footer-color: #fff;
}

html {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}

html,
body {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    height: 100%;
    width: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
}

#root {
    height: 100%;
    width: 100%;
}

body {
    height: 100%;
    width: 100%;
}

h1,
h2 {
    font-weight: 700;
    font-size: 36px;
}

h3 {
    font-weight: 700;
    font-size: 24px;
    color: var(--main-color);
}

p {
    font-size: 18px;
}

a {
    color: var(--main-color);
}

.text-important {
    color: var(--main-color);
}

.text-bold {
    font-weight: 700;
}

.navbar .navbar-brand {
    margin-left: 60px;
    margin-right: 80px;
    font-size: 36px;
    font-weight: 700;
    color: var(--nav-link-color);
}

.navbar .navbar-nav .nav-link {
    color: var(--nav-link-color);
    margin-right: 30px;
    font-size: 18px;
    transition: 0.25s all;
}

.navbar .navbar-nav .nav-link:hover {
    transition: 0.25s all;
}

.hero {
    position: relative;
    width: 100%;
}

.hero .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero .hero-text h1 {
    background-color: var(--highlight-color);
    padding-left: 15px;
    padding-right: 15px;
}

#about {
    margin-top: 50px;
}

#what-i-do {
    margin-top: 100px;
}

#what-i-do .row {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

#projects {
    margin-top: 100px;
}

#projects h2 {
    margin-bottom: 75px;
}

#project-thumbnails .img-container {
    cursor: pointer;
    position: relative;
}

#project-thumbnails .mx-auto {
    padding-top: 35px;
}

#project-thumbnails .mx-auto .img-container {
    transition: all 0.5s;
}

#project-thumbnails .mx-auto:hover .img-container {
    transform: scale(1.2);
    transition: all 0.5s;
}

#project-thumbnails .overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--highlight-color);
    transition: all 0.5s;
}

#project-thumbnails .img-container svg {
    position: absolute;
    z-index: 2;
    color: rgba(0, 0, 0, 0.8);
    top: 50%;
    left: 50%;
    width: 25%;
    height: 25%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.5s;
}

#project-thumbnails .img-container:hover svg {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0);
    transition: all 0.5s;
}

#project-thumbnails .img-container:hover .overlay {
    opacity: 1;
    transition: all 0.5s;
}

#contact {
    margin-top: 100px;
}

#contact h2 {
    margin-bottom: 50px;
}

#contact form {
    margin-top: 30px;
    margin-bottom: 30px;
}
#contact input,
#contact textarea {
    margin-top: 15px;
}

#contact .btn-primary {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

footer {
    margin-top: 100px;
    padding: 25px;
    background-color: var(--main-color);
}

footer p {
    color: var(--footer-color);
    text-align: center;
}

@media only screen and (max-width: 600px) {
    h1,
    h2 {
        font-size: 28px;
    }

    .navbar-brand {
        margin-left: 0;
        font-size: 28px;
    }

    .hero .hero-text {
        width: 63%;
    }

    #what-i-do {
        margin-top: 75px;
    }

    #projects {
        margin-top: 50px;
    }

    #projects h2 {
        margin-bottom: 25px;
    }

    #contact {
        margin-top: 75px;
    }

    #contact h2 {
        margin-bottom: 25px;
    }

    footer {
        margin-top: 75px;
    }
}

@media only screen and (max-width: 360px) {
    .hero .hero-text {
        width: 83%;
    }
}
